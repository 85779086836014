import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { AlertCircleOutlineIc } from '@dsch/dd-icons';

export interface ErrorMessageProps {
  text: string;
  className?: string;
}

export const Text = styled.p`
  ${({ theme }) => theme.fontSize.S12};
  color: ${({ theme }) => theme.colors.RED_DARK};
  min-height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const SAlertCircleOutlineIc = styled(AlertCircleOutlineIc)`
  margin-right: ${({ theme }) => theme.spacing.S4};
  margin-top: -1px;
`;

/**
 * Sub component used in conjunction with other inputs.
 */
function ErrorMessage({ text, className }: ErrorMessageProps) {
  const theme = useContext(ThemeContext);
  return (
    <Text className={className}>
      <SAlertCircleOutlineIc
        color={theme.colors.RED_DARK}
        width="16"
        height="16"
      />{' '}
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </Text>
  );
}

export { ErrorMessage };
